import React from "react"
import Seo from "../components/SEO"
import styled from "styled-components"
import { Link } from "gatsby"
import Button from "../components/Button"


const Placeholder = styled.div`
  height: 10vh;
`

const JobsPage = () => (
  <>
    <Seo title="Jobs" description="Jobs at GreenFintech" />
    <h1>Jobs</h1>
    <p>
    GreenFintech.org works with fintechs, financial institutions, governments and NGOs to build tomorrow’s green finance organisations and innovations.
    </p>
    <p>
      We believe in organization specific journeys. Tailor made and based on values and specific objectives.
    </p>
    <p>
      We apply advanced technologies to do good. Creating partnerships and to deliver sustainable value.
    </p>
    <p>
      Interested to contribute to GreenFintech.org and our client journeys? Please contact us to explore opportunities.
    </p>
    <Placeholder></Placeholder>
    <Link href="/contact">
          <Button className="btn-link" to="/contact" text="Start our conversation" />
    </Link>
    <Placeholder></Placeholder>
  </>
)

export default JobsPage